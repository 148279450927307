import { formLabelClasses } from '@mui/material';
import { TFunction } from 'i18next';

/**
 * ConvertDateToISOString
 * @param date date with format: dd/MM/yyyy
 * @returns date with format: yyyy-MM-dd
 */
const convertDateToISOString = (date: any) => {
	if (!isValidFieldRequired(date)) return '0001-01-01';
	if (typeof date !== 'string') return '0001-01-01';
	if (!date.includes('/')) return '0001-01-01';

	return (
		date.split('/')[2] +
		'-' +
		('0' + date.split('/')[1]).slice(-2) +
		'-' +
		('0' + date.split('/')[0]).slice(-2)
	);
};

/**
 * ConvertDateToISOString
 * @param date date with format: dd/MM/yyyy
 * @returns date with format: yyyy-MM-dd or Null
 */
const convertDateToISOStringOrNull = (date: any) => {
	if (!isValidFieldRequired(date)) return '';
	if (typeof date !== 'string') return '';
	if (!date.includes('/')) return '';

	return (
		date.split('/')[2] +
		'-' +
		('0' + date.split('/')[1]).slice(-2) +
		'-' +
		('0' + date.split('/')[0]).slice(-2)
	);
};

/**
 * ConvertDateISOToDateString
 * @param date date with format: 2018-07-19T22:07:00.000-03:00
 * @returns date with format: dd/MM/yyyy
 */
const convertDateISOToDateString = (date: string): string => {
	if (!isValidFieldRequired(date)) return '01/01/0001';
	if (typeof date !== 'string') return '01/01/0001';
	if (!date.includes('-')) return '01/01/0001';
	const d = new Date(date);
	return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1)
		.toString()
		.padStart(2, '0')}/${d.getFullYear()}`;
};

const initValue = (field: any) => {
	return field === '' || field === undefined || field === null ? '' : field;
};

const initValueString = (field: any): string => {
	return field === '' || field === undefined || field === null ? '' : field;
};

const getValueFormik = (values: any | null, field: string) => {
	return field === '' || field === undefined || field === null ? '' : values[field];
};

const getErrorFormik = (errors: any | null, field: string) => {
	return field === '' || field === undefined || field === null ? '' : errors[field];
};
const getCheckedValueFormik = (values: any | null, field: string, value: any) => {
	console.log(value);
	console.log(field);
	console.log(values);

	return field === '' || field === undefined || field === null
		? false
		: !!!values[field] && values[field]?.find((v: any) => v === value);
};

const isValidFieldRequired = (field: any): boolean =>
	field === '' || field === undefined || field === null ? false : true;

const msgInValidLimitCharacter = (
	text: string | undefined,
	max: number,
	min: number | undefined = 0,
	t: TFunction<'translation', undefined>
): string => {
	if (!text) {
		if (min != 0) {
			return `${t('¡El campo debe tener al menos 300 caracteres!', { num: min })}\n\n0/${max}`;
		}
		return '';
	}

	if (min != 0 && text.length < min) {
		return `${t('¡El campo debe tener al menos 300 caracteres!', { num: min })}\n\n${
			text.length
		}/${max}`;
	} else if (text.length > max) {
		return `${t('¡El campo debe tener al maximo caracteres!', { num: max })}\n\n${
			text.length
		}/${max}`;
	}

	return '';
};

const isInValidLimitCharacter = (
	text: string | undefined,
	max: number,
	min: number | undefined = 0
) => {
	if (!text) return false;
	if (min != 0 && text.length < min) {
		return false;
	} else if (text.length > max) {
		return false;
	}

	return text.length > 0;
};

const capitalizeFirstLetter = (str: any) => str.replace(/^./, str[0].toUpperCase());

const setValueOrDefault = (value: any, typeClassMember: string) => {
	switch (typeClassMember) {
		case 'string':
			return value === '' || value === undefined || value === null ? '' : value;
		case 'boolean':
			return value === '' || value === undefined || value === null ? false : value;
		case 'number':
			return value === '' || value === undefined || value === null ? 0 : value;
		default:
			return '';
	}
};

/**
 * regExpPassword
 * @returns match to use in forming passwords with at least one uppercase letter, at least one lowercase letter, at least one special character and at least one.
 */
const regExpPassword = () =>
	/^(?=(.*[A-Z]){1})(?=(.*[a-z]){1})(?=(.*[0-9]){1})(?=(.*[@#$%^!&+=.\-_*]){1})([a-zA-Z0-9@#$%^!&+=*.\-_]){8,}$/;

/**
 * regExpPhoneNumber10Digits
 * @returns match to use in the formation for validation of Colombian telephone (10 digits).
 */
const regExpPhoneNumber10Digits = () => /[0-9]{10}/gm;

/**
 * regExpPhoneNumber12Digits
 * @returns match to use in the formation for validation of Colombian telephone (12 digits (DDI - XX) (DDD - XXX) XXX-XXXX).
 */
// const regExpPhoneNumber12Digits = () => /[0-9]{12}/gm;
const regExpPhoneNumber12Digits = () => /[0-9]/gm;

/**
 * matchPhoneNumber12Digits
 * @returns match to use in the formation for validation of Colombian telephone mask (DDI - XX) (DDD - XXX) XXXXXXX).
 */
const matchPhoneNumber12Digits = () => /(\d{2})(\d{3})(\d{7})/;

/**
 * matchEmail
 * @returns match to use in the formation for validation of email.
 */
const matchEmail = () =>
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

/**
 * formatMaskPhoneNumber12Digits
 * @returns Mask +XX-XXX-XXXXXXX.
 */
const formatMaskPhoneNumber12Digits = (value: string) => {
	// value = value.replace(/\D/g, '');
	// let x = value.replace(/\D/g, '').match(matchPhoneNumber12Digits());
	// if (x !== null) value = '+' + x[1] + '-' + x[2] + '-' + x[3];

	// return value;
	return value.replace(/\D/g, '');
};

/**
 * isValidPhoneNumber
 * @returns true/false.
 */
const isValidPhoneNumber = (value: string): boolean => {
	if (
		value
			?.replace(/[^\d]/g, '')
			?.replaceAll('-', '')
			?.replaceAll('+', '')
			?.match(regExpPhoneNumber12Digits())
	)
		return true;

	return false;
};
/**
 * isValidEmail
 * @returns true/false.
 */
const isValidEmail = (value: string): boolean => {
	if (value?.match(matchEmail())) return true;

	return false;
};

/**
 * getDateNowToString
 * @returns date now in yyyy-MM-dd format string
 */
const getDateNowToString = () => {
	var d = new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
};

const formatStringToDate = (dt: string | any) => {
	if (dt?.includes('-')) return dt;
	if (dt?.includes('/')) {
		let parts = dt?.split('/');
		return `${parts[2]}-${parts[1]}-${parts[0]}`;
	}
	return '';
};
const formatDateToString = (dt: string | any) => {
	if (dt?.includes('/')) return dt;
	if (dt?.includes('-')) {
		let parts = dt?.split('-');
		return `${parts[2]}/${parts[1]}/${parts[0]}`;
	}
	return '';
};

export {
	convertDateToISOString,
	convertDateISOToDateString,
	convertDateToISOStringOrNull,
	initValue,
	initValueString,
	capitalizeFirstLetter,
	isValidFieldRequired,
	setValueOrDefault,
	regExpPassword,
	regExpPhoneNumber10Digits,
	regExpPhoneNumber12Digits,
	matchPhoneNumber12Digits,
	formatMaskPhoneNumber12Digits,
	matchEmail,
	isValidPhoneNumber,
	isValidEmail,
	getDateNowToString,
	getValueFormik,
	getCheckedValueFormik,
	getErrorFormik,
	msgInValidLimitCharacter,
	isInValidLimitCharacter,
	formatStringToDate,
	formatDateToString,
};
