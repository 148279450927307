import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import PaginationComponent from '../../components/Pagination/Index';
import styleScss from './styles.module.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import {
	getCurriculoVagaById,
	getVagasPostuladas,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../../app/modules/auth';
import { useTranslation } from 'react-i18next';
import RequestVacancyCardComponent from '../../components/RequestVacancyCardComponent';

const CandidateRequestedVacancyPage = () => {
	const { t } = useTranslation();
	const { currentUser } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const navigate = useNavigate();

	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [id, setId] = useState<string>('');
	const openMenu = Boolean(anchorEl);
	const id_users = currentUser?.user?.id;
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
		setAnchorEl(event.currentTarget);
		setId(id);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
		setId('');
	};

	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
		let arr: any[] = [];
		for (let index = 0; index < 10; index++) {
			let x = {
				id: 1,
				vacancy: `El nombre de la vacante ${index}`,
				employer: `El nombre de lo empleador ${index}`,
				description: `CUIDADOR DE ADULTO MAYOR PREPARACIóN DE ALIMENTACIóN ESPECIAL ${index}`,
				status: 'Fechada',
			};
			arr.push(x);
		}
		//setData(arr);
		handleSubmit();
	}, []);

	useEffect(() => {
		handleSubmit();
	}, [filter]);

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};

	const handleSubmit = async () => {
		const mapVagasPostuladas = (vagas: any) => {
			return vagas.map((vaga: any) => ({
				id: vaga.id,
				id_empresa: vaga.CompanyVacancy.company_id,
				numero_puestos: vaga.CompanyVacancy.position_count,
				nombre_vacante: vaga.CompanyVacancy.job_title,
				responsable_vacante: vaga.CompanyVacancy.job_responsible,
				persona_solicita_cargo: vaga.CompanyVacancy.requester,
				telefono_contacto: vaga.CompanyVacancy.contact_phone,
				Correo_eletrenico_contacto: vaga.CompanyVacancy.contact_email,
				asesor_responsable: vaga.CompanyVacancy.responsible_advisor,
				Describa_vacante: vaga.CompanyVacancy.job_description,
				nivel_estudio: vaga.CompanyVacancy.education_level,
				graduado: vaga.CompanyVacancy.graduated ? 'true' : 'false',
				formacion_titulo: vaga.CompanyVacancy.education_title,
				requiere_experiencia_relacionada: vaga.CompanyVacancy.experience_required
					? 'true'
					: 'false',
				requiere_capacitacion_especifica: vaga.CompanyVacancy.training_required ? 'true' : 'false',
				vacante_susceptible_teletrabajo: vaga.CompanyVacancy.telework_possible ? 'true' : 'false',
				rango_experiencia: vaga.CompanyVacancy.experience_range,
				descricion_capacitacion_especifica: vaga.CompanyVacancy.training_description,
				fecha_limite_aplicacion: vaga.CompanyVacancy.application_deadline,
				fecha_estimada_incorporacion: vaga.CompanyVacancy.estimated_start_date,
				tipo_contrato: vaga.CompanyVacancy.contract_type,
				salario_mensual: vaga.CompanyVacancy.monthly_salary,
				jornada_laboral: vaga.CompanyVacancy.work_schedule,
				vacante_confidencial: vaga.CompanyVacancy.confidential_job ? 'true' : 'false',
				vacante_gestionada_prestador: vaga.CompanyVacancy.outsourced_job ? 'true' : 'false',
				trabajo_suplementario: vaga.CompanyVacancy.job_type,
				tipo_vacante: vaga.CompanyVacancy.job_type,
				prestador_alterno: vaga.CompanyVacancy.street,
				punto_atencion: vaga.CompanyVacancy.complement,
				districto: vaga.CompanyVacancy.district,
				createdAt: vaga.CompanyVacancy.created_at,
				status: vaga.CompanyVacancy.status === 'true',
				Empresa: {
					id: vaga?.CompanyVacancy?.Company?.id,
					//idhashEmpresa: vaga.Company.id,
					tipo_documento: vaga?.CompanyVacancy?.Company?.document_type,
					numero_documento: vaga?.CompanyVacancy?.Company?.document_number,
					razon_social: vaga?.CompanyVacancy?.Company?.company_name,
					naturaleza: vaga?.CompanyVacancy?.Company?.company_type,
					tipo: vaga?.CompanyVacancy?.Company?.company_type,
					Tamano_empleados: vaga?.CompanyVacancy?.Company?.number_employees,
					pertence: vaga?.CompanyVacancy?.Company?.is_headquarters ? 'true' : 'false',
					local: vaga?.CompanyVacancy?.Company?.street,
					id_coordenada: vaga?.CompanyVacancy?.Company?.latitude,
					id_coordenada_02: vaga?.CompanyVacancy?.Company?.longitude,
					id_coordenada_03: vaga?.CompanyVacancy?.Company?.city,
					id_coordenada_04: vaga?.CompanyVacancy?.Company?.state,
					id_coordenada_05: vaga?.CompanyVacancy?.Company?.district,
					id_coordenada_06: vaga?.CompanyVacancy?.Company?.country,
					id_coordenada_07: vaga?.CompanyVacancy?.Company?.latitude,
					id_coordenada_08: vaga?.CompanyVacancy?.Company?.longitude,
					id_coordenada_09: vaga?.CompanyVacancy?.Company?.city,
					tipo_complemento: vaga?.CompanyVacancy?.Company?.complement,
					codigo_postal: vaga?.CompanyVacancy?.Company?.document_number,
					direccion_residencia: vaga?.CompanyVacancy?.Company?.street,
					pais: vaga?.CompanyVacancy?.Company?.country,
					departamento: vaga?.CompanyVacancy?.Company?.state,
					municipio: vaga?.CompanyVacancy?.Company?.city,
					prestador_principal: vaga?.CompanyVacancy?.Company?.phone_number,
					punta_atencion: vaga?.CompanyVacancy?.Company?.phone_number,
					latitud: vaga?.CompanyVacancy?.Company?.latitude,
					longitud: vaga?.CompanyVacancy?.Company?.longitude,
					localidad: vaga?.CompanyVacancy?.Company?.district,
					active: vaga?.CompanyVacancy?.Company?.active,
					status: vaga?.CompanyVacancy?.Company?.status,
					telefono: vaga?.CompanyVacancy?.Company?.phone_number,
					nombre_sede: vaga?.CompanyVacancy?.Company?.company_name,
				},
				UserCurriculos: [
					{
						id: vaga.id_user_cv,
						id_users: vaga.id_user_cv,
						curriculo_vagas: {
							id_vagas: vaga.id,
							id_curriculo: vaga.id_user_cv,
						},
					},
				],
			}));
		};

		try {
			console.log('chamou eu nova rota');
			const users = await getVagasPostuladas(
				`page=${filter.page}&limit=${filter.limit}&job_title=${
					userData.nombre_vacante ? userData.nombre_vacante : ''
				}`
			);

			const mappedUsers = mapVagasPostuladas(users.data.data);

			setTotal(users.data.total);
			setTotalPage(users.data.totalPages);
			setData(mappedUsers);

			//console.log(userData);
			//const retVacancy = await createVacancy(userData);
			//console.log(retVacancy);
			//alert('¡Retorno da consulta   ' + users.data.total  );
			//navigate('/auth');
			//setLoading(false);
		} catch (e: any) {
			console.error(e);
			var erro = e;
			alert(e + ' ' + e.response.data.error);
		}
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<h3 className={styleScss.mobileTitle}>{t('Vacantes Postuladas')}</h3>
					<div className='col-sm-4'>
						<div className='input-group mb-3'>
							<input
								type='text'
								className={`form-control ${styleScss.mobileInput}`}
								value={userData['nombre_vacante']}
								onChange={(e) => setUserData({ ...userData, nombre_vacante: e.target.value })}
								placeholder={t('Nombre de la vacante')}
								aria-label='Nombre de la vacante'
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span
									className={`input-group-text ${styleScss.mobileIcon}`}
									style={{
										cursor: 'pointer',
										color: 'blue',
										textDecoration: 'underline',
										fontSize: 16,
										borderTopLeftRadius: '0',
										borderBottomLeftRadius: '0',
									}}
									onClick={handleSubmit}
									id='searchVacancy'
								>
									<i className='bi bi-search' style={{ fontSize: '20px' }}></i>
								</span>
							</div>
						</div>
					</div>
				</div>
				{data.lenght !== 0 && (
					<>
						<div className={`${styleScss.table2}`}>
							<table className={`table table-responsive table-striped`}>
								<thead className={`${styleScss['th-custom']}`}>
									<tr>
										<th scope='col'>
											<h4>{t('Vacante')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Empleador')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Descripción')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Estado')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Acciones')}</h4>
										</th>
									</tr>
								</thead>
								<tbody className={`${styleScss['td-custom']}`}>
									{data.map((x: any, i: number) => (
										<tr key={i} id={i.toString()}>
											<th className='pt-3 pb-3' scope='row'>
												{x.nombre_vacante}
											</th>
											<td>{x.Empresa.razon_social}</td>
											<td>{x.Describa_vacante}</td>
											<td>{x.status ? t('Aberta') : t('Fechada')}</td>
											<td>
												<Button
													id='basic-button'
													aria-controls={openMenu ? 'basic-menu' : undefined}
													aria-haspopup='true'
													aria-expanded={openMenu ? 'true' : undefined}
													onClick={(e) => handleClickMenu(e, x.id)}
												>
													<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
												</Button>

												<Menu
													id='basic-menu'
													anchorEl={anchorEl}
													open={openMenu}
													onClose={handleCloseMenu}
													MenuListProps={{
														'aria-labelledby': 'basic-button',
													}}
												>
													<MenuItem onClick={handleCloseMenu}>
														<Link to={`/candidato/vacante/${id}`}>{t('Ver vacante')}</Link>
													</MenuItem>
												</Menu>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className='cardMobile'>
							{data.map((x: any, i: number) => (
								<RequestVacancyCardComponent
									data={x}
									openMenu={openMenu}
									handleClickMenu={handleClickMenu}
									anchorEl={anchorEl}
									handleCloseMenu={handleCloseMenu}
								/>
							))}
						</div>
					</>
				)}

				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
		</>
	);
};

const CandidateRequestedVacancyWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Vacantes Postuladas')}</PageTitle>
			<CandidateRequestedVacancyPage />
		</>
	);
};

export { CandidateRequestedVacancyWrapper };
